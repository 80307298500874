<ion-content [fullscreen]="true">
  <div class="my-flex ion-justify-content-center ion-align-items-center height-webkit-fill main-background my-flex-column space-between">
    <div class="ion-text-center ion-hide-sm-up">
      <img src="assets/icon/logo.svg" alt="The logo" class="logo" width="250px">
      <p class="light-purple-text">Building societies and empowering student communities</p>
    </div>
    <div class="page-container-border w-600">
      <div class="page-container padding-25 background-light">
        <div class="my-flex ion-justify-content-center ion-align-items-center ion-hide-sm-down">
          <img src="assets/icon/logo.svg" alt="The logo" class="logo" width="250px">
        </div>
        <div class="mobile-form-border padding-bottom-0 padding-1 border-top-left-radius-12 border-top-right-radius-12">
          <div class="background-light border-top-left-radius-12 border-top-right-radius-12">
            <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
              <div class="form-content-container">
                <div class="label-mb">
                  <ion-label class="ion-padding-start">EMAIL</ion-label>
                </div>
                <div class="ion-margin-bottom">
                  <div class="form-input-wrapper">
                    <div class="form-input-color">
                      <ion-input fill="outline" shape="round" class="ion-padding-start" formControlName="email" type="text" placeholder="University Email"></ion-input>
                    </div>
                  </div>
                </div>
      
                <div class="ion-margin-bottom">
                  <div class="label-mb">
                    <ion-label class="ion-padding-start">PASSWORD</ion-label>
                  </div>
                  <div class="form-input-wrapper">
                    <div class="form-input-color">
                      <ion-input shape="round" fill="outline" class="ion-padding-start" formControlName="password" type="password" placeholder="Enter your password"></ion-input>
                    </div>
                  </div>
                </div>
                <div class="ion-margin-top">
                  <div class="ion-text-center">
                    <ion-checkbox formControlName="rememberMe" labelPlacement="end">
                      <span>Remember me</span>
                      </ion-checkbox>
                  </div>
                </div>
                <div class="ion-margin-top my-flex ion-justify-content-center ion-align-items-center">
                  <div>
                    <div class="button-border">
                      <div class="button-background">
                        <ion-button size="small" class="button-color ion-padding-horizontal" shape="round" expand="full" type="submit">Sign In</ion-button>
                      </div>
                    </div>
                    <div class="ion-margin-top ion-text-center ion-hide-sm-up">
                      <small (click)="openForgotPasswordModal('modal')">
                        <span class="mb-display">
                          Forgot Password?
                        </span>
                      </small>
                    </div>
                    <div class="ion-margin-top button-border ion-hide-sm-down">
                      <div class="button-background">
                        <ion-button size="small" class="button-color ion-padding-horizontal" shape="round" expand="full" (click)="openForgotPasswordModal('popup')">Forgot password?</ion-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ion-margin-top ion-text-center">
                  First time?
                  <a routerLink="/register">
                    <span class="ion-padding-start gradient-text">Request access here.</span>
                  </a>
                </div>
                <div *ngIf="errorMessage" class="ion-text-center">
                  <ion-text color="danger">
                    {{ errorMessage }}
                  </ion-text>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>