import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet
  ],
  providers: []
})
export class AppComponent {
  constructor(
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document
  ) {
    // this.keyboardListener();
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.handleKeyboardEvents();
    });
  }

  handleKeyboardEvents() {
    const platform = Capacitor.getPlatform();
    if (platform === 'ios' || platform === 'android') {
      // Only add keyboard event listeners for iOS/Android
      Keyboard.addListener('keyboardWillShow', () => {
        const content = document.querySelector('ion-content');
        if (content) {
          content.style.setProperty('--keyboard-offset', '290px');
        }
      });

      Keyboard.addListener('keyboardWillHide', () => {
        const content = document.querySelector('ion-content');
        if (content) {
          content.style.setProperty('--keyboard-offset', '0px');
        }
      });
    } else {
      this.document.querySelector('html')?.classList.add('keyboard-closed');
    }
  }

  keyboardListener() {
    window.addEventListener('keyboardDidShow', (event: any) => {
      this.document.querySelector('html')?.classList.add('keyboard-open');
    })
    window.addEventListener('keyboardDidHide', () => {
      this.document.querySelector('html')?.classList.remove('keyboard-open');
    })
  }
}