<ion-content [fullscreen]="true">
  <div class="mb-padding-12 my-flex ion-justify-content-center ion-align-items-center height-webkit-fill main-background">
    <div class="page-container-border w-600">
      <div class="page-container padding-25 background-light" >
        <div class="ion-padding">
          <div class="my-flex ion-justify-content-center ion-align-items-center">
            <img src="assets/icon/logo.svg" alt="The logo" class="logo" width="250px">
          </div>
          <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()"> <!--[formGroup]="" (ngSubmit)=""-->
            <h1 class="mb-text-center ion-text-center" style="font-weight: 900;">
              RESET PASSWORD
            </h1>
            <h1 class="gradient-text mb-text-center ion-text-center" style="font-weight: 900;">
              LET'S GET YOU BACK ONLINE.
            </h1>
            <div class="form-content-container">
              <div class="label-mb">
                <ion-label class="ion-padding-start">NEW PASSWORD</ion-label>
              </div>
              <div class="ion-margin-bottom">
                <div class="form-input-wrapper">
                  <div class="form-input-color">
                    <ion-input fill="outline" shape="round" class="ion-padding-start" formControlName="password" type="password" placeholder="New Password"></ion-input>
                  </div>
                </div>
              </div>
    
              <div class="ion-margin-bottom">
                <div class="label-mb">
                  <ion-label class="ion-padding-start">CONFIRM PASSWORD</ion-label>
                </div>
                <div class="form-input-wrapper">
                  <div class="form-input-color">
                    <ion-input shape="round" fill="outline" class="ion-padding-start" formControlName="confirm_password" type="password" placeholder="Confirm Password"></ion-input>
                  </div>
                </div>
              </div>
              <div class="ion-margin-top my-flex ion-justify-content-center ion-align-items-center">
                <div class="w-60">
                  <div class="button-border">
                    <div class="button-background">
                      <ion-button size="small" class="button-color" shape="round" expand="full" type="submit">SUBMIT</ion-button>
                    </div>
                  </div>
                  <ion-button class="cancel-outline-btn" shape="round" expand="full" fill="outline" (click)="cancel()">CANCEL</ion-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>  
</ion-content>
