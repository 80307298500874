import { Routes } from '@angular/router';
import { authRoutes } from './pages/auth/auth.route';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    children: authRoutes
  },
  {
    path: 'socielite-admin',
    loadComponent: () =>
      import('./pages/socielite-admin/socielite-admin.page').then(
        (m) => m.SocieliteAdminPage
      ), children: [
        { path: '', redirectTo: 'home', pathMatch: 'full' },
        { path: 'home', loadComponent: () => import('./pages/socielite-admin/home/home.component').then(m => m.HomeComponent) },
        { path: 'prospects', loadComponent: () => import('./pages/socielite-admin/prospects/prospects.component').then(m => m.ProspectsComponent) },
        { path: 'clients', loadComponent: () => import('./pages/socielite-admin/clients/clients.component').then(m => m.ClientsComponent) },
        { path: 'prospect', loadComponent: () => import('./pages/socielite-admin/prospect-detail/prospect-detail.component').then(m => m.ProspectDetailComponent) },
        { path: 'client', loadComponent: () => import('./pages/socielite-admin/client-detail/client-detail.component').then(m => m.ClientDetailComponent) },
        { path: 'invoices', loadComponent: () => import('./pages/socielite-admin/component/invoices/invoices.component').then(m => m.InvoicesComponent) },
        { path: 'automatic-invoices', loadComponent: () => import('./pages/socielite-admin/component/automatic-invoices/automatic-invoices.component').then(m => m.AutomaticInvoicesComponent) },
        { path: 'communications', loadComponent: () => import('./pages/socielite-admin/component/communications/communications.component').then(m => m.CommunicationsComponent) },
        { path: 'support', loadComponent: () => import('./pages/socielite-admin/component/support/support.component').then(m => m.SupportComponent) },
      ]
  },

];
