export const APIENDPOINT = {
    AUTH:{
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout',
        FORGOTPASSWORD: 'auth/password-reset-email',
        RESETPASSWORD: 'auth/reset-password'
    },
    PROSPECT:{
        ACTIVE: 'prospect/active-prospects',
        CLOSED: 'prospect/closed-prospects',
        DETAIL: 'prospect/prospect-detail/', //prospectId
        ASSIGN: 'prospect/assign/', //prospectId/{user_id}
        CHANGESTATUS: 'prospect/change-status/', //prospect/change-status/{universityId}/{status}
        CHANGESTAGE: 'prospect/change-stage/', //prospect/change-stage/{universityId}/{stage}
        UPLOADDOCUMENT: (prospectId: number) => `prospect/${prospectId}/upload-document`, //prospect/{universityId}/upload-document
        GETDOCUMENTS: (prospectId: number, offset: number) => `prospect/${prospectId}/get-documents/${offset}`, //prospect/{universityId}/get-documents/{offset?}
        ADDNOTE: (prospectId: number) => `prospect/${prospectId}/add-note`, //prospect/{universityId}/add-note
        GETNOTES: (prospectId: number, offset: number) => `prospect/${prospectId}/get-notes/${offset}`, //prospect/{universityId}/add-note/{offset?}
    },
    CLIENT: {
        LIST: 'client/list', //list/{status}/{offset?}/{name?}
    },
    USERS: {
        GETSOCIELITEADMINS: 'users/socielite-admins', //users/socielite-admins/{offset}/{name}
    }
}